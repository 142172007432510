<template>
	<div id="app">
		<nav>
			<router-link to="/"></router-link>
		</nav>
		<router-view />
	</div>
</template>
<script>
export default {
	name: 'home',
	data() {
		return {

		}
	},
	
}

</script>

<style lang="scss" >
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	// text-align: center;
	background: #fff;
	color: #2c3e50;
}

::-webkit-scrollbar {
	width: 7px;
	height: 7px;
}

::-webkit-scrollbar-thumb {
	border-radius: 5px;
	background: #C2C8D5;
}

::-webkit-scrollbar-track {
	border-radius: 10px;
	background: #EFF1F5;
}

.hover {
	cursor: pointer !important;
}

@media (max-width: 1200px) {
	#app {
		overflow: hidden;
	}
	html {
		overflow-x: hidden;
	}
}

* {
	margin: 0;
	padding: 0;
}
@import "@/assets/scss/common.scss"
</style>

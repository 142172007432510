import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

export const routes = [
	{
		path: '/',
		name: 'home',
		component: () => import(`../views/home.vue`)
	},
]
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return routerPush.call(this, location).catch(error => error)
}



const router = new VueRouter({
	routes
})
router.reloadRouter = function () {
	router.matcher = new VueRouter({
		routes
	}).matcher
}
let hasRoles = true
router.beforeEach(async (to, from, next) => {
	next();
	
})

export default router
